import { Project } from "./Project";

export type ProjectCommissionReportsGeneration = {
  url: string | null;
  status: ProjectCommissionReportsGenerationStatus;
  project: Project;
};

export enum ProjectCommissionReportsGenerationStatus {
  noRooms = "no_rooms",
  notRequested = "not_requested",
  generated = "generated",
  frozen = "frozen",
  generating = "generating",
  requested = "requested",
}
