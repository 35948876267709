import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Component } from "react";
import { FormattedMessage, InjectedIntlProps, injectIntl } from "react-intl";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import { PrimaryBlueButton, PrimaryGreenButton } from "nvent-web/components/Button";
import LinkButton from "nvent-web/components/Button/LinkButton";
import { LoadingSection } from "nvent-web/components/LoadingSection";
import Modal from "nvent-web/components/Modal";
import Api from "nvent-web/services/Api";
import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { CommissioningFormValues } from "nvent-web/types/CommissioningFormValues";
import { DetailedProject } from "nvent-web/types/DetailedProject";
import { DetailedRoom } from "nvent-web/types/DetailedRoom";

import CommissioningForm from "./components/CommissioningForm";
import ProjectDetailsItem from "./components/ProjectDetailsItem";
import RoomItem from "./components/RoomItem";
import style from "./ProjectDetails.module.scss";

interface ProjectDetailsParams {
  projectId: string;
}

interface ProjectsProps extends RouteComponentProps<ProjectDetailsParams>, InjectedIntlProps {
  projects: ProjectsStore;
  rooms: RoomsStore;
  api: Api;
  notifications: NotificationsStore;
}

interface ProjectsState {
  isCommissioningModalOpen: boolean;
  isCommissioningSuccessModalOpen: boolean;
  commissioningValues: CommissioningFormValues | undefined;
}

export const COMPLETED_PROGRESS = 1;

export class ProjectDetails extends Component<ProjectsProps, ProjectsState> {
  state = {
    isCommissioningModalOpen: false,
    isCommissioningSuccessModalOpen: false,
    commissioningValues: undefined,
  };

  @observable
  private reportDownloading = false;

  @observable
  private billOfMaterialsDownloading = false;

  componentDidMount() {
    this.props.projects.getProject(Number(this.props.match.params.projectId));
  }

  renderDetails(values: DetailedProject) {
    const { id } = values;
    const { getLocalState } = this.props.projects;
    const { areDetailsExpanded, toggleDetailsExpanded } = getLocalState(id);

    return (
      <ProjectDetailsItem
        key={id}
        data={values}
        onEdit={this.editProjectItem}
        onGetPDF={this.downloadReport}
        onGetBOM={this.downloadBillOfMaterials}
        isExpanded={areDetailsExpanded}
        toggleExpanded={toggleDetailsExpanded}
        hasProducts={this.hasProducts()}
        reportDownloading={this.reportDownloading}
        billOfMaterialsDownloading={this.billOfMaterialsDownloading}
      />
    );
  }

  render() {
    const { projectDetails, areProjectDetailsLoading } = this.props.projects;
    const { isCommissioningModalOpen, isCommissioningSuccessModalOpen, commissioningValues } = this.state;

    if (areProjectDetailsLoading) {
      return <LoadingSection />;
    }

    return (
      <>
        <PageTitle>
          <FormattedMessage id="projects.details.title" />
        </PageTitle>
        {projectDetails && (
          <>
            <div className={style.wrapper}>
              <div className={style.inner}>{this.renderDetails(projectDetails)}</div>
            </div>
            <div className={style.commissioning}>
              {projectDetails.progress === COMPLETED_PROGRESS && (
                <>
                  {!projectDetails.finished && (
                    <h3 className={style.ready}>
                      <FormattedMessage id="commissioning.ready" />
                    </h3>
                  )}
                  <PrimaryGreenButton className={style.button} onClick={this.handleCommissioning}>
                    {projectDetails.finished ? (
                      <FormattedMessage id="send.report" />
                    ) : (
                      <FormattedMessage id="actions.commissioning" />
                    )}
                  </PrimaryGreenButton>
                </>
              )}
            </div>
            <div className={style.roomListHeader}>
              <p>
                <FormattedMessage id="projects.details.roomList" />
              </p>
              {!projectDetails.finished && (
                <LinkButton
                  to={`/projects/${projectDetails.id}/rooms/new`}
                  theme="secondaryBlue"
                  className={style.addButton}
                >
                  <FormattedMessage id="actions.add" />
                </LinkButton>
              )}
            </div>
            <div className={style.listWrapper}>
              {projectDetails.rooms.length > 0 ? (
                this.roomsList(projectDetails.id, projectDetails.rooms, projectDetails.finished)
              ) : (
                <h2 className={style.noContent}>
                  <FormattedMessage id="rooms.messages.noContent" />
                </h2>
              )}
            </div>
          </>
        )}
        <Modal center isOpen={isCommissioningModalOpen} handleClose={this.handleCommissioningModalClose}>
          <CommissioningForm onSubmit={this.onCommissioningSuccess} onClose={this.handleCommissioningModalClose} />
        </Modal>
        <Modal center isOpen={isCommissioningSuccessModalOpen} handleClose={this.handleCommissioningSuccessModalClose}>
          {commissioningValues && this.renderSuccessModalInner(commissioningValues)}
        </Modal>
      </>
    );
  }

  private onCommissioningSuccess = (commissioningValues: CommissioningFormValues) => {
    this.setState({ isCommissioningModalOpen: false, isCommissioningSuccessModalOpen: true, commissioningValues });
  };

  private hasProducts = () => {
    const { projectDetails } = this.props.projects;
    return Boolean(
      projectDetails && projectDetails.rooms && projectDetails.rooms.find((room) => room.products.length !== 0)
    );
  };

  private handleCommissioningSuccessModalClose = () => {
    this.setState({ isCommissioningSuccessModalOpen: false, commissioningValues: undefined });

    this.props.history.push("/projects/finished");
  };

  private handleCommissioning = () => {
    const { projectDetails } = this.props.projects;

    if (projectDetails && projectDetails.allRoomsHavePhotos) {
      this.setState({ isCommissioningModalOpen: true });
    } else {
      this.props.notifications.createError(<FormattedMessage id="commissioning.installationPhotos.validation" />);
    }
  };

  private handleCommissioningModalClose = () => {
    this.setState({ isCommissioningModalOpen: false });
  };

  private renderSuccessModalInner(values?: CommissioningFormValues) {
    return (
      <>
        <h3 className={style.title}>
          <FormattedMessage id="commissioning.success" />
        </h3>
        <p className={style.notice}>
          <FormattedMessage id="commissioning.reportSuccessfullySent" />
        </p>
        {values && (
          <>
            <p>{values.installerEmail}</p>
            <p>{values.clientEmail}</p>

            {values.additionalEmails.split(",").map((email) => (
              <p key={email}>{email}</p>
            ))}
          </>
        )}

        <div className={style.actions}>
          <PrimaryBlueButton className={style.okButton} onClick={this.handleCommissioningSuccessModalClose}>
            <FormattedMessage id="actions.ok" />
          </PrimaryBlueButton>
        </div>
      </>
    );
  }

  private editProjectItem = (id: number) => {
    this.props.history.push(`/projects/${id}/edit`);
  };

  private downloadReport = async (id: number) => {
    this.reportDownloading = true;

    try {
      const { data } = await this.props.projects.downloadReport(id);
      if (window.open(data.url, "_blank")) {
        return;
      } else {
        window.location.href = data.url;
      }
    } catch (error) {
      logger.error(error);
      this.props.notifications.createError(<FormattedMessage id="error.reportDownloadFailed" />);
    } finally {
      this.reportDownloading = false;
    }
  };

  private downloadBillOfMaterials = async (id: number) => {
    this.billOfMaterialsDownloading = true;

    try {
      const { data } = await this.props.projects.downloadBillOfMaterials(id);
      if (window.open(data.url, "_blank")) {
        return;
      } else {
        window.location.href = data.url;
      }
    } catch (error) {
      logger.error(error);
      this.props.notifications.createError(<FormattedMessage id="error.reportDownloadFailed" />);
    } finally {
      this.billOfMaterialsDownloading = false;
    }
  };

  private roomsList(projectId: number, rooms: DetailedRoom[], finishedProject: boolean) {
    const { getLocalState } = this.props.rooms;

    return rooms.map((item) => {
      const { isExpanded, toggleExpanded } = getLocalState(item.id);

      return (
        <RoomItem
          key={item.id}
          data={item}
          projectId={projectId}
          onRemove={this.removeRoomItem}
          onEdit={this.editRoomItem}
          disableActions={finishedProject}
          onCopy={this.copyRoomItem}
          toggleExpanded={toggleExpanded}
          isExpanded={isExpanded}
        />
      );
    });
  }

  private removeRoomItem = async (id: number) => {
    const projectId = Number(this.props.match.params.projectId);
    await this.props.rooms.removeRoom(projectId, id);
    await this.props.projects.getProject(projectId);
  };

  private editRoomItem = (id: number) => {
    const projectId = Number(this.props.match.params.projectId);
    this.props.history.push(`/projects/${projectId}/rooms/${id}/edit`);
  };

  private copyRoomItem = async (id: number) => {
    const projectId = Number(this.props.match.params.projectId);
    await this.props.rooms.copyRoom(projectId, id);
    await this.props.projects.getProject(projectId);
  };
}

export default compose<ProjectsProps, Record<string, unknown>>(
  inject("projects", "rooms", "notifications", "api"),
  injectIntl,
  observer
)(ProjectDetails);
