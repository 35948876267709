import { inject, observer } from "mobx-react";
import { Component } from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { compose } from "recompose";

import AddToHomeScreenPrompt from "nvent-web/components/AddToHomeScreenPrompt";
import { Notifications } from "nvent-web/components/Notifications/Notifications";
import ProjectCommissionReport from "nvent-web/components/ProjectCommissionReport";
import AddRoom from "nvent-web/scenes/AddRoom";
import { certifiedPageFinishedForLocale } from "nvent-web/scenes/CertifiedPro/certifiedPageFinishedForLocale";
import { CertifiedProPage } from "nvent-web/scenes/CertifiedPro/CertifiedProPage";
import { NonCertifiedProPage } from "nvent-web/scenes/CertifiedPro/NonCertifiedProPage";
import { ContinueCreatingProject } from "nvent-web/scenes/ContinueCreatingProject/ContinueCreatingProject";
import CustomerSupportNumbers from "nvent-web/scenes/CustomerSupportNumbers/CustomerSupportNumbers";
import EditProject from "nvent-web/scenes/EditProject";
import EditRoom from "nvent-web/scenes/EditRoom";
import { IndependentSelectionGuide } from "nvent-web/scenes/IndependentSelectionGuide/IndependentSelectionGuide";
import NewProject from "nvent-web/scenes/NewProject";
import ProjectDetails from "nvent-web/scenes/ProjectDetails";
import FinishedProjects from "nvent-web/scenes/Projects/FinishedProjects";
import OpenProjects from "nvent-web/scenes/Projects/OpenProjects";
import RoomDetails from "nvent-web/scenes/RoomDetails";
import SelectionGuide from "nvent-web/scenes/SelectionGuide/SelectionGuide";
import Tests from "nvent-web/scenes/Tests";
import UserSettings from "nvent-web/scenes/UserSettings";
import withTracker from "nvent-web/services/withTracker";
import AppStore from "nvent-web/stores/App";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { UserStore } from "nvent-web/stores/User";
import { getAwaitingProject } from "nvent-web/utils/awaitingProject";

import { Advertisements } from "./Advertisements";
import Header from "./Header";
import { Sidebar } from "./Sidebar/Sidebar";

export interface AuthenticatedAppInnerProps extends RouteComponentProps {
  app: AppStore;
  notifications: NotificationsStore;
  user: UserStore;
}

export class AuthenticatedAppInner extends Component<AuthenticatedAppInnerProps> {
  render() {
    const {
      notifications,
      user: { certifiedProNumber, isProfileComplete },
      app,
    } = this.props;

    const awaitingProject = getAwaitingProject();

    return (
      <Sidebar>
        <Header />
        <Notifications notifications={notifications} />
        <Switch>
          <Route path="/settings" exact={true} component={UserSettings} />
          {!isProfileComplete && <Redirect to="/settings" />}

          <Route path="/projects/open" exact={true} component={OpenProjects} />
          <Route path="/projects/new" exact={true} component={NewProject} />
          <Route path="/projects/continue" exact={true} component={ContinueCreatingProject} />
          <Route path="/projects/finished" exact={true} component={FinishedProjects} />
          <Route path="/projects/:projectId" exact={true} component={ProjectDetails} />
          <Route path="/projects/:projectId/commission-report" exact={true} component={ProjectCommissionReport} />
          <Route path="/projects/:projectId/edit" exact={true} component={EditProject} />
          <Route path="/projects/:projectId/rooms/new" exact={true} component={AddRoom} />
          <Route path="/projects/:projectId/rooms/:roomId" exact={true} component={RoomDetails} />
          <Route path="/selection-guide" render={() => <IndependentSelectionGuide fullWidth />} />
          <Route
            path="/projects/:projectId/rooms/:roomId/room_products/:productId/tests"
            exact={true}
            component={Tests}
          />
          <Route path="/projects/:projectId/rooms/:roomId/edit" exact={true} component={EditRoom} />
          {certifiedPageFinishedForLocale(app.locale, Boolean(certifiedProNumber)) && (
            <Route
              path="/certifiedpro"
              exact={true}
              component={certifiedProNumber ? CertifiedProPage : NonCertifiedProPage}
            />
          )}
          <Route path="/customer-support" exact={true} component={CustomerSupportNumbers} />
          <Route path="/projects/:projectId/rooms/:roomId/selection-guide" component={SelectionGuide} />
          <Redirect to={awaitingProject ? "/projects/continue" : "/projects/open"} />
        </Switch>
        <AddToHomeScreenPrompt />
        <Advertisements />
      </Sidebar>
    );
  }
}

export const AuthenticatedApp = compose<AuthenticatedAppInnerProps, Record<string, unknown>>(
  withRouter,
  withTracker,
  inject("app", "notifications", "user"),
  observer
)(AuthenticatedAppInner);
