import classNames from "classnames";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

import { ProgressProps } from "..";

import style from "./FullProgress.module.scss";

interface ProgressFullProps extends ProgressProps {
  hideLabel?: boolean;
  noBorder?: boolean;
  className?: {
    text?: string;
  };
}

const ProgressFull: FunctionComponent<ProgressFullProps> = ({ progress, hideLabel, noBorder, className = {} }) => {
  const progressPercentage = `${Math.floor(progress * 100)}%`;

  return (
    <div className={classNames(style.progress, { [style.noBorder]: noBorder })}>
      <div
        className={classNames(style.progressBar, { [style.noBorder]: noBorder })}
        role="progressbar"
        style={{ width: progressPercentage }}
      >
        {!hideLabel && (
          <div className={classNames(style.text, className.text)}>
            {progressPercentage} <FormattedMessage id="projects.progress.completedTests" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressFull;
