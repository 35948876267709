import { inject } from "mobx-react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";

import PageTitle from "nvent-web/App/components/PageTitle";
import { PrimaryBlueButton } from "nvent-web/components/Button";
import { LoadingSection } from "nvent-web/components/LoadingSection";
import { LoadingSpinner } from "nvent-web/components/LoadingSpinner";
import style from "nvent-web/components/ProjectCommissionReport/ProjectCommissionReport.module.scss";
import { usePolling } from "nvent-web/hooks/usePolling";
import PublicApi from "nvent-web/services/PublicApi";
import {
  ProjectCommissionReportsGeneration,
  ProjectCommissionReportsGenerationStatus,
} from "nvent-web/types/ProjectsCommissionReportsGeneration";

interface ProjectCommissionReportProps extends RouteComponentProps<{ projectId: string }> {
  publicApi: PublicApi;
}

type ReportGenerationState = {
  url: ProjectCommissionReportsGeneration["url"];
  status: ProjectCommissionReportsGeneration["status"];
  projectName: ProjectCommissionReportsGeneration["project"]["name"];
};

const STOP_POLLING_STATUSES = [
  ProjectCommissionReportsGenerationStatus.noRooms,
  ProjectCommissionReportsGenerationStatus.frozen,
  ProjectCommissionReportsGenerationStatus.generated,
];
const ProjectCommissionReport = (props: ProjectCommissionReportProps) => {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isTokenInvalid, setIsTokenInvalid] = useState<boolean>(false);
  const [reportGeneration, setReportGeneration] = useState<ReportGenerationState | null>(null);
  const projectId = props.match.params.projectId;
  const projectToken = getProjectTokenFromQueryString(props.location.search) || "";

  usePolling(
    async () => {
      try {
        const { status, url, project } = await fetchGenerationStatus({ api: props.publicApi, projectId, projectToken });
        setReportGeneration({ status, url, projectName: project.name });
      } catch (_error) {
        setIsTokenInvalid(true);
      } finally {
        setIsPageLoading(false);
      }
    },
    { isActive: !reportGeneration || !STOP_POLLING_STATUSES.includes(reportGeneration.status) }
  );

  const handleDownloadButtonClick = async () => {
    if (!reportGeneration || !reportGeneration.url?.length)
      throw new Error("Attempt to download report without an url");

    await props.publicApi.commissionReport.download(projectId, projectToken);
  };

  if (isTokenInvalid)
    return (
      <div className={style.container}>
        <p className={style.tokenInvalidTitle}>
          <FormattedMessage id="commissionReportDownload.invalidToken" />
        </p>
        <p className={style.tokenInvalidDescription}>
          <FormattedMessage id="commissionReportDownload.urlYouProvidedIsInvalid" />
        </p>
      </div>
    );

  return (
    <div className={style.container}>
      <PageTitle>
        <FormattedMessage id="commissionReportDownload.commissionReportDownload" />
      </PageTitle>
      <div className={style.content}>
        {isPageLoading && <LoadingSection />}
        {!isPageLoading && reportGeneration && (
          <>
            <p className={style.projectName}>{reportGeneration.projectName}</p>
            <StatusIndicator status={reportGeneration.status} />
            {reportGeneration.url?.length && <DownloadButton onClick={handleDownloadButtonClick} />}
          </>
        )}
      </div>
    </div>
  );
};

const getProjectTokenFromQueryString = (queryString: string) => {
  const queryParams = new URLSearchParams(queryString);

  return queryParams.get("projectToken");
};

const fetchGenerationStatus = async ({
  api,
  projectId,
  projectToken,
}: {
  api: PublicApi;
  projectId: string;
  projectToken: string;
}) => {
  const { data: existingGenerationData } = await api.commissionReportGeneration.get(projectId, projectToken);

  if (existingGenerationData.status !== ProjectCommissionReportsGenerationStatus.notRequested)
    return existingGenerationData;

  await api.commissionReportGeneration.create(projectId, projectToken || "");
  const { data: createdGenerationData } = await api.commissionReportGeneration.get(projectId, projectToken);

  return createdGenerationData;
};

const StatusIndicator = ({ status }: { status: ProjectCommissionReportsGenerationStatus }) => {
  if (status === ProjectCommissionReportsGenerationStatus.notRequested) return <></>;
  if (status === ProjectCommissionReportsGenerationStatus.noRooms) return <></>;

  const statusMap = {
    requested: "commissionReportDownload.reportInGenerationQueue",
    generating: "commissionReportDownload.reportBeingGenerated",
    frozen: "commissionReportDownload.reportGenerationSuspended",
    generated: "commissionReportDownload.reportGenerated",
  };
  const messageId = statusMap[status];

  return (
    <div className={style.statusIndicator}>
      <FormattedMessage id={messageId} />
      {["requested", "generating"].includes(status) && <LoadingSpinner className={style.spinner} />}
    </div>
  );
};

const DownloadButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <PrimaryBlueButton onClick={onClick} className={style.downloadButton}>
      <FormattedMessage id="commissionReportDownload.downloadReport" />
    </PrimaryBlueButton>
  );
};

export default compose<ProjectCommissionReportProps, Record<string, unknown>>(
  withRouter,
  inject("publicApi")
)(ProjectCommissionReport);
